<template>
  <v-dialog
    v-model="showModal"
    fluid
    max-width="900"
  >
    <v-card
      outlined
      shaped
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style ml-7 mb-2">
        <v-icon class="icon-style">
          mdi-pencil
        </v-icon> Create User
      </h2>
      <v-container
        class="py-0"
      >
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="firstName"
                  label="First Name*"
                  :rules="[(v) => !!v || 'This field is required']"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="lastName"
                  label="Last Name*"
                  :rules="[(v) => !!v || 'This field is required']"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <address-form
                  ref="addressForm"
                  @addressEntered="assiginAddress($event)"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="phoneNumber"
                  label="Phone-Number"
                  type="number"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="email"
                  label="Email*"
                  :rules="emailRules"
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="password"
                  label="Create your password"
                  min="8"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="[(v) => !!v || 'Password is required']"
                  counter
                  required
                  @click:append="showPassword = !showPassword"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="role"
                  menu-props="auto"
                  :items="roleItems"
                  label="Role*"
                  :rules="[(v) => !!v || 'This field is required']"
                  single-line
                />
              </v-col>
            </v-row>
          </v-form>
          <br>
          <p class="text-sm-left">
            *Indicates required field
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red darken-3"
            @click="showModal = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="#FF3700"
            @click="submit()"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import Constants from 'src/constants';
  import AddressForm from 'src/views/dashboard/component/AddressForm';

  export default {
    name: 'CreateUser',
    components: {
      'centre-spinner': spinner,
      'address-form': AddressForm,
    },
    data () {
      return {
        firstName: '',
        lastName: '',
        address: {},
        phoneNumber: null,
        role: '',
        roleItems: ['userAdmin', 'user'],
        valid: false,
        showPassword: false,
        loading: false,
        password: '',
        email: '',
        emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
        ],
        showModal: true,
      };
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    methods: {
      submit () {
         this.$refs.addressForm.getEnteredAdress();
        if (this.$refs.form.validate() === false || Object.keys(this.address).length === 0) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        if (Object.keys(this.address).length > 0) {
          this.loading = true;
          this.$store.dispatch('users/createUser', {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          password: this.password,
          address: this.address,
          phone_number: this.phoneNumber,
          role: this.role,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'User created successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.showModal = false;
          this.address = {};
          this.$emit('get-users-list');
        }).catch(() => {
          this.loading = false;
          this.showModal = false;
        });
        };
      },
      assiginAddress (Address) {
        this.address = Address;
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 35px;
}
</style>
