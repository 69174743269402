<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
  >
    <v-card
      shaped
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-account-lock-open
        </v-icon> Assign permission group
      </h2>
      <v-form
        ref="form"
      >
        <v-container>
          <v-card-text class="text-body-1 text-center">
            <p class=" my-p-style ">
              Please select a permission group from below list
            </p>
            <v-row
              class="fill-height"
              align-content="center"
              justify="center"
            >
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="selectedPermissionGroup"
                  :items="availablePermissionGroup"
                  item-value="id"
                  item-text="name"
                  label="Select Permission Group"
                  :rules="[(v) => !!v || 'This field is required']"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              @click="showModal = false"
            >
              cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              :disabled="!isPermissionGroupSelected"
              @click="assignPermissionGroup()"
            >
              Assign
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>
<script>
import Constants from 'src/constants';
import spinner from 'src/views/dashboard/component/SpinnerCentre';

export default {
  name: 'AssiginUserPermissionGroup',
  components: {
    'centre-spinner': spinner,
  },
   props: {
      userid: {
        type: String,
        required: true,
      },
      permissionGroupId: {
        type: String,
        default: null,
      },
    },
  data () {
    return {
      showModal: true,
      selectedPermissionGroup: null,
      loading: false,
    };
  },
  computed: {
    availablePermissionGroup () {
      return this.$store.getters['accountsettings/getPermissionGroups'];
    },
    isPermissionGroupSelected () {
      return this.selectedPermissionGroup !== null;
    },
  },
  watch: {
    showModal: function (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
        this.selectedPermissionGroup = null;
      }
    },
  },
    async mounted () {
      this.loading = true;
      this.selectedPermissionGroup = this.permissionGroupId;
      await this.$store.dispatch('accountsettings/fetchPermissionGroups');
      this.loading = false;
    },
  methods: {
    assignPermissionGroup () {
      this.$store.dispatch('users/assignPermissionGroup', {
        groupId: this.selectedPermissionGroup,
        userId: this.userid,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Permission group assigned successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$emit('get-users-list');
          this.showModal = false;
      });
    },
  },
};
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-p-style {
  font-size: 16px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
}
</style>
