<template>
  <v-dialog
    v-model="showModal"
    max-width="800"
  >
    <v-card
      shaped
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon
          class="icon-style"
          left
        >
          mdi-clipboard-text-multiple
        </v-icon>{{ pageheader }}
      </h2>
      <br>
      <div class="text-field-styling">
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="search"
            label="Search Category"
            append-icon="mdi-magnify"
            outlined
            dense
          />
        </v-col>
      </div>
      <p
        class="p-style"
      >
        {{ groupname }}
      </p>
      <v-btn
        color="#37474F"
        depressed
        text
        @click="expandCategories()"
      >
        <v-icon left>
          {{ customIcon }}
        </v-icon>
        {{ customNaming }}
      </v-btn>
      <div class="no-category-alert">
        <p v-if="showNoCategoryAlert">
          No categories found for your search.
        </p>
      </div>
      <div
        class="div-style"
      >
        <v-list
          color="#ECEFF1"
        >
          <v-list-group
            v-for="category in filteredPermissionCategoryList"
            :key="category"
            sub-group
            :value="expandList"
            color="black"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="header-style">
                  {{ category.toUpperCase() }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-chip-group
              column
              multiple
              class="pl-10"
            >
              <template v-for="(permission, index) in assignedpermissionslist">
                <v-chip
                  v-if="category === permission.category"
                  :key="index"
                  color="#37474F"
                  outlined
                >
                  {{ permission.name }}
                </v-chip>
              </template>
            </v-chip-group>
          </v-list-group>
        </v-list>
      </div>
      <br>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-3"
          class="ma-3"
          @click="showModal=false"
        >
          Close
        </v-btn>
      </v-card-actions>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-dialog>
</template>

<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'CreatePermissionGroup',
    components: {
      'centre-spinner': spinner,
    },
    props: {
      assignedpermissionslist: {
        type: Array,
        required: true,
      },
      groupname: {
        type: String,
        required: true,
      },
      pageheader: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        showModal: true,
        loading: false,
        search: '',
        expandList: true,
        customIcon: 'mdi-arrow-collapse-all',
        customNaming: 'Compress All',
      };
    },
    computed: {
      permissionCategoryList () {
        return this.$store.getters['accountsettings/getPermissionCategoryList'];
      },
      filteredPermissionCategoryList () {
        return this.permissionCategoryList.filter((file) => {
          return file.toUpperCase().match(this.search.toUpperCase());
        });
      },
      showNoCategoryAlert () {
        if (this.filteredPermissionCategoryList.length === 0) {
          return true;
        } else {
          return false;
        }
      },
    },
     watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
          this.selectedPermissions = [];
        }
      },
    },
    async mounted () {
      this.loading = true;
      await this.$store.dispatch('accountsettings/fetchPermissionCategoryList');
      this.loading = false;
    },
    methods: {
      expandCategories () {
        if (this.expandList === false) {
          this.customIcon = 'mdi-arrow-collapse-all';
          this.customNaming = 'Compress All';
          this.expandList = true;
        } else {
          this.customIcon = 'mdi-arrow-expand-all';
          this.customNaming = 'Expand All';
          this.expandList = false;
        }
      },
    },
  };

</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 20px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.header-style {
    font-size: 16px;
    font-weight: bold;
    color: #37474F;
}
.div-style {
    margin-right: auto;
    margin-left: auto;
}
.search-style {
 margin-right: 10px;
}
.text-field-styling {
 margin-left: 20px;
 margin-right: 20px;
}
.p-style {
  text-align: center;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  color: #FF3700;
  font-size: 20px;
}
.no-category-alert {
  color: #C62828;
  text-align: center;
  font-size: 18px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
}
</style>
