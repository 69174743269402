<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <create-user
      v-if="showCreateUserForm"
      @closed="showCreateUserForm=false"
      @get-users-list="getUsersList"
    />
    <assign-user-permission-group
      v-if="showAssignUserPermissionGroupForm"
      :userid="userExternalId"
      :permission-group-id="permissionGroupId"
      @closed="showAssignUserPermissionGroupForm=false"
      @get-users-list="getUsersList"
    />
    <assigned-permissions-list
      v-if="showAssignedPermissionGroupDetails"
      :assignedpermissionslist="assignedPermissionsList"
      :groupname="permissionGroupName"
      :pageheader="pageHeader"
      @closed="showAssignedPermissionGroupDetails = false"
    />
    <delete-user
      v-if="showDelete"
      :title="'Delete User ?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-account-multiple
      </v-icon> Users List
    </h2>
    <br>
    <v-alert
      v-if="!isOrganizationGotAnyPurchaseHistory"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="alert-style"
    >
      Please purchase our "Advanced" plan type before availing of this feature.
    </v-alert>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <v-col
        cols="12"
        class="text-right"
      >
        <v-btn
          color="#FF3700"
          @click="createUser()"
        >
          CREATE USER
        </v-btn>
      </v-col>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              dense
              label="Search"
              append-icon="mdi-magnify"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="usersTable.headers"
        :items=" usersList"
        :search="search"
        class="elevation-1"
        mobile-breakpoint="100"
      >
        <template #item="{ item }">
          <tr>
            <td>{{ item.first_name }}</td>
            <td>{{ item.last_name }}</td>
            <td>
              {{ item.organization.name }}
            </td>
            <td>
              <p v-if="item.role === null">
                Not assigned
              </p>
              <p v-else>
                {{ item.role.name }}
              </p>
            </td>
            <td>
              <p v-if="item.address === null">
                Not assigned
              </p>
              <p v-else>
                {{ item.address.line1 }},{{ item.address.line2 }}<font v-if="item.address.line2 !== null">
                  ,
                </font>{{ item.address.city }},{{ item.address.state }},{{ item.address.country }}
              </p>
            </td>
            <td>{{ item.email }}</td>
            <td>{{ item.updated_at | diffHumans }}</td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="assignPermissionGroup(item.external_id,item.permission_group_id)"
              >
                <v-icon> mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="red"
                @click="deleteUser(item.external_id)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="fetchAssignedPermissionGroupDetails(item.permission_group_id)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <br>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>

<script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import CreateUser from './CreateUser.vue';
  import AssignUserPermissionGroup from './AssignUserPermissionGroup.vue';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import Constants from 'src/constants';
  import AssignedPermissionsList from '../AccountSettings/AssignedPermissionsList.vue';

  export default {
    name: 'UsersList',
    components: {
      'centre-spinner': spinner,
      'create-user': CreateUser,
      'assign-user-permission-group': AssignUserPermissionGroup,
      'delete-user': DeleteDialog,
      'assigned-permissions-list': AssignedPermissionsList,
    },
    filters: {
      diffHumans (val) {
        return moment(val).fromNow();
      },
    },
    data () {
      return {
        search: '',
        loading: false,
        usersTable: {
          headers: [
            { text: 'First Name', align: 'start', value: 'first_name' },
            { text: 'Last Name', value: 'last_name' },
            { text: 'Organization Name', value: 'name' },
            { text: 'Role', value: 'name' },
            { text: 'Address', value: 'address' },
            { text: 'Email', value: 'email' },
            { text: 'Last Updated', value: 'updated_at' },
            { text: 'Edit', value: 'edit' },
            { text: 'Delete', value: 'delete' },
            { text: 'More', value: 'data-table-expand' },
          ],
        },
        showCreateUserForm: false,
        showAssignUserPermissionGroupForm: false,
        userExternalId: '',
        showDelete: false,
        deleteId: '',
        permissionGroupId: '',
        showAssignedPermissionGroupDetails: false,
        assignedPermissionsList: [],
        permissionGroupName: '',
        pageHeader: '',
      };
    },
    computed: {
      usersList () {
        return this.$store.getters['users/getUsersList'];
      },
      permissionGroupDetails () {
        return this.$store.getters['users/getpermissionGroupDetails'];
      },
      isPermissionGroupAvailable () {
        return Object.keys(this.permissionGroup).length > 0;
      },
      subscriptionsHistory () {
        return this.$store.getters['payments/getSubscriptionsHistory'];
      },
      oneTimePaymentHistory () {
        return this.$store.getters['payments/getOneTimePaymentHistory'];
      },
      paymentHistory () {
        const history = [...this.subscriptionsHistory, ...this.oneTimePaymentHistory];
        return history;
      },
      isOrganizationGotAnyPurchaseHistory () {
        if (this.paymentHistory.length > 0) {
          return true;
        }
        return false;
      },
    },
    async mounted () {
      await this.getUsersList();
      await this.fetchPaymentHistory();
    },
    methods: {
      async getUsersList () {
        this.loading = true;
        await this.$store.dispatch('users/fetchUsersList');
        this.loading = false;
      },
      createUser () {
        if (this.isOrganizationGotAnyPurchaseHistory === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please purchase our "Advanced" plan type before availing of this feature.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.showCreateUserForm = true;
      },
      assignPermissionGroup (userid, permissionGroupId) {
      this.userExternalId = userid;
      this.permissionGroupId = permissionGroupId;
      this.showAssignUserPermissionGroupForm = true;
      },
      async fetchAssignedPermissionGroupDetails (permissionGroupId) {
        this.loading = true;
        await this.$store.dispatch('users/clearPermissionGroupDetails');
        if (permissionGroupId === null) {
          this.loading = false;
          this.$store.dispatch('alert/onAlert', {
            message: 'No permission group found',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        await this.$store.dispatch('users/fetchPermissionGroupDetails', permissionGroupId).then(response => {
          this.assignedPermissionsList = this.permissionGroupDetails.permissions;
          this.permissionGroupName = this.permissionGroupDetails.name;
          this.pageHeader = 'Permission Group Details';
          this.loading = false;
          this.showAssignedPermissionGroupDetails = true;
        });
      },
      deleteUser (userExternalId) {
        this.showDelete = true;
        this.deleteId = userExternalId;
      },
      deleteConfirmed () {
        this.$store.dispatch('users/deleteUser', this.deleteId)
        .then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'User deleted.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.getUsersList();
        }).finally(
          this.showDelete = false,
          this.deleteId = null,
        );
      },
      async fetchPaymentHistory () {
        this.loading = true;
        await this.$store.dispatch('payments/fetchSubscriptionsHistory');
        await this.$store.dispatch('payments/fetchOneTimePaymentHistory');
        this.loading = false;
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC;
}
.v-data-table {
  background-color: #ECEFF1
}
.alert-style {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
</style>
