<template>
  <v-dialog
    v-model="showModal"
    max-width="800"
  >
    <v-card
      shaped
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon
          class="icon-style"
          left
        >
          mdi-account-cog
        </v-icon>Update Group
      </h2>
      <br>
      <div class="text-field-styling">
        <v-row>
          <v-col
            cols="12"
            md="7"
          >
            <v-text-field
              v-model="permissionGroupName"
              filled
              dense
              hint="Name for your reference - 'Manager-Permissions'."
              persistent-hint
              label="Edit Group Name"
              prepend-inner-icon="mdi-pencil"
            />
          </v-col>
          <v-col
            cols="12"
            md="5"
          >
            <v-text-field
              v-model="search"
              label="Search Category"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </div>
      <br>
      <div class="p-style">
        <p>Manage Group Permissions</p>
      </div>
      <v-btn
        color="#37474F"
        depressed
        text
        @click="expandCategories()"
      >
        <v-icon left>
          {{ customIcon }}
        </v-icon>
        {{ customNaming }}
      </v-btn>
      <div class="no-category-alert">
        <p v-if="showNoCategoryAlert">
          No categories found for your search.
        </p>
      </div>
      <div
        class="div-style"
      >
        <v-list
          color="#ECEFF1"
        >
          <v-list-group
            v-for="category in filteredPermissionCategoryList"
            :key="category"
            sub-group
            :value="expandList"
            color="black"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="header-style">
                  {{ category.toUpperCase() }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item-group
              multiple
              class="pl-10"
            >
              <template v-for="(item, index) in permissionsList">
                <v-list-item
                  v-if="category === item.category"
                  :key="'item'+ index"
                  class="list-style"
                  color="black"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <strong>
                        {{ item.name.toUpperCase() }}
                      </strong>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      style="color:#FF3700"
                      class="subtitle-style"
                    >
                      {{ item.description }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-switch
                    v-model="selectedPermissions"
                    :value="item.id"
                    color="#FF3700"
                  />
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list-group>
        </v-list>
      </div>
      <br>
      <v-card-actions>
        <v-btn
          color="red darken-3"
          class="ma-3"
          @click="showModal=false"
        >
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          color="#FF3700"
          class="ma-3"
          :disabled="!isPermissionsSelected"
          @click="updatePermissionGroup()"
        >
          Update
        </v-btn>
      </v-card-actions>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-dialog>
</template>

<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'CreatePermissionGroup',
    components: {
      'centre-spinner': spinner,
    },
     props: {
      groupname: {
        type: String,
        required: true,
      },
      groupid: {
        type: String,
        required: true,
      },
      existingpermissions: {
        type: Array,
        required: true,
      },
    },
    data () {
      return {
        showModal: true,
        selectedPermissions: [],
        loading: false,
        search: '',
        permissionGroupName: '',
        expandList: false,
        customIcon: 'mdi-arrow-expand-all',
        customNaming: 'Expand All',
      };
    },
    computed: {
      permissionCategoryList () {
        return this.$store.getters['accountsettings/getPermissionCategoryList'];
      },
      permissionsList () {
        return this.$store.getters['accountsettings/getPermissionsList'];
      },
      filteredPermissionCategoryList () {
        return this.permissionCategoryList.filter((file) => {
          return file.toUpperCase().match(this.search.toUpperCase());
        });
      },
      isPermissionsSelected () {
        return this.selectedPermissions.length !== 0 && this.permissionGroupName !== '';
      },
      showNoCategoryAlert () {
        if (this.filteredPermissionCategoryList.length === 0) {
          return true;
        } else {
          return false;
        }
      },
    },
     watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
          this.selectedPermissions = [];
        }
      },
    },
    async mounted () {
      this.loading = true;
      await this.$store.dispatch('accountsettings/fetchPermissionCategoryList');
      await this.$store.dispatch('accountsettings/fetchPermissionsList');
      await this.showExistingPermissions();
      this.loading = false;
    },
    methods: {
      showExistingPermissions () {
          this.permissionGroupName = this.groupname;
        for (const permission of this.existingpermissions) {
          this.selectedPermissions.push(permission.id);
        }
      },
      expandCategories () {
        if (this.expandList === false) {
            this.customIcon = 'mdi-arrow-collapse-all';
            this.customNaming = 'Compress All';
            this.expandList = true;
        } else {
            this.customIcon = 'mdi-arrow-expand-all';
            this.customNaming = 'Expand All';
            this.expandList = false;
        }
      },
      updatePermissionGroup () {
        this.$store.dispatch('accountsettings/updatePermissionGroup', {
          groupId: this.groupid,
          groupName: this.permissionGroupName,
          selectedPermissions: this.selectedPermissions,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Successfully updated your permission group.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.showModal = false;
          this.$store.dispatch('accountsettings/fetchPermissionGroups');
        });
      },
    },
  };

</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 20px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-p-style {
  font-size: 18px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
}
.my-label-style {
   font-size: 16px;
}
.header-style {
    font-size: 16px;
    font-weight: bold;
    color: #37474F;
}
.subtitle-style {
   font-size: 13px;
   font-weight: bold;
}
.div-style {
    margin-right: auto;
    margin-left: auto;
}
.search-style {
 margin-right: 10px;
}
.list-style {
  background-color: #CFD8DC;
  border-radius: 10px;
}
.text-field-styling {
 margin-left: 20px;
 margin-right: 20px;
}
.p-style {
  text-align: center;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  color: #FF3700;
  font-size: 20px;
}
.no-category-alert {
  color: #C62828;
  text-align: center;
  font-size: 18px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
}
@media (max-width: 767px) {
  .v-application--is-ltr .v-list-group--sub-group .v-list-group__items {
    padding-right: 10px;
    padding-left: 10px;
  }
}
</style>
